import PropTypes from 'prop-types'
import Chip from '../stories/Chip'

const getType = label => {
  switch (label) {
    case 'bundle':
      return 'bundle'
    case 'redeemed':
      return 'success'
    case 'cancelled':
    case 'error':
      return 'error'
    default:
      return 'info'
  }
}

const VouchersChip = ({ label = 'info', ...props }) => {
  return <Chip label={label} type={getType(label)} {...props} />
}

VouchersChip.propTypes = {
  label: PropTypes.string,
}

export default VouchersChip
