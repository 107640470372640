import { useState, useCallback } from 'react'
// import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { GENDER_OPTIONS } from '../libs/validators'
import { setMergeOrCreateDocument } from '../firebase/firestore'
import PhoneField from '../stories/PhoneInput'
import SelectField from '../stories/Select'
import TextField from '../stories/TextField'
import DobPicker from './DobPicker'
import Button from '../stories/Button'
import { Collapse } from '@mui/material'
import { parse, isValid, add } from 'date-fns'
import { Timestamp } from 'firebase/firestore'

const ProfileForm = ({ uid, values }) => {
  const [firstname, setFirstname] = useState(values?.firstname || '')
  const [lastname, setLastname] = useState(values?.lastname || '')
  const [phone, setPhone] = useState(values?.phone || '')
  const [dob, setDob] = useState(values?.dob || values?.birthday || '')
  const [gender, setGender] = useState(values?.gender || '')
  const [raceEthnicity, setRaceEthnicity] = useState(values?.raceEthnicity || [])
  const [insured, setInsured] = useState(values?.insuranceInfo?.insured || false)
  const [insuranceProvider, setInsuranceProvider] = useState(
    values?.insuranceInfo?.insuranceProvider || '',
  )

  const [savingData, setSavingData] = useState('Save Changes')

  const processProfileUpdate = useCallback(async () => {
    setSavingData('Saving...')

    let date = parse(dob, 'yyyy-MM-dd', new Date())
    let newDob = null

    // If the date is still not valid, try to parse it directly
    if (!isValid(date)) {
      date = new Date(dob)
    }

    if (isValid(date)) {
      // Set the time to midnight UTC to avoid timezone issues
      date = add(date, { hours: -date.getUTCHours(), minutes: -date.getUTCMinutes(), seconds: -date.getUTCSeconds(), milliseconds: -date.getUTCMilliseconds() })
      newDob = Timestamp.fromDate(date)

      //   await doc.ref.set({
      //     customer: {
      //       dob: newDob
      //     },
      //   }, { merge: true })
      //   console.log('New DOB: ', format(newDob.toDate(), 'MM/dd/yyyy'))
    } else {
      console.error('Invalid date')
    }

    await setMergeOrCreateDocument('profiles', uid, {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      dob: newDob,
      gender: gender,
      raceEthnicity: raceEthnicity,
      insuranceInfo: {
        insured: insured,
        insuranceProvider: insured ? insuranceProvider : '',
      },
    })
      .then(() => {
        console.log('this works too')
      })
      .catch(error => {
        console.warn('there was an error')
        console.error(error)
      })
    console.log('this does work after')
    setSavingData('Saved')
    const timeout = setTimeout(() => {
      setSavingData('Save Changes')
      clearTimeout(timeout)
    }, 3000)
  }, [firstname, lastname, phone, dob, gender, raceEthnicity, insured, insuranceProvider, uid])

  const handleChange = event => {
    const {
      target: { value },
    } = event
    setRaceEthnicity(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value === undefined ? '' : value,
    )
  }

  return (
    <Box>
      <TextField
        required
        name='firstname'
        label='first name'
        value={firstname}
        onChange={e => setFirstname(e.target.value)}
      />
      <TextField
        required
        name='lastname'
        label='last name'
        value={lastname}
        onChange={e => setLastname(e.target.value)}
      />
      <PhoneField
        required
        name='phone'
        label='Telephone number'
        value={phone}
        onChange={e => setPhone(e.target.value)}
      />
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={12} sm={7}>
          <Box mt={2}>
            <DobPicker dob={dob} setDob={setDob} />
          </Box>
          {/* <DateField
            required
            name='dob'
            label='Date of Birth'
            value={formatDateForUI(dob)}
            onChange={e => setDob(e.target.value)}
          /> */}
        </Grid>
        <Grid item xs={12} sm={5}>
          <SelectField
            required
            label='gender'
            name='gender'
            options={GENDER_OPTIONS}
            value={gender}
            onChange={e => setGender(e.target.value)}
          />
        </Grid>
      </Grid>
      <SelectField
        label='Race/Ethnicity'
        name='raceEthnicity'
        multiple
        value={raceEthnicity}
        onChange={handleChange}
        options={[
          { value: 'preferNotToSay', label: 'Prefer not to say' },
          {
            value: 'americanIndian',
            label: 'American Indian or Alaska Native',
          },
          { value: 'asian', label: 'Asian' },
          { value: 'hispanic', label: 'Hispanic or Latino' },
          { value: 'black', label: 'Black or African American' },
          {
            value: 'nativeHawaiian',
            label: 'Native Hawaiian or Other Pacific Islander',
          },
          { value: 'white', label: 'White' },
          { value: 'other', label: 'Other' },
        ]}
      />
      <SelectField
        label='Insured'
        name='insured'
        value={insured}
        onChange={e => {
          setInsured(e.target.value)
        }}
        options={[
          { value: false, label: 'Not Insured' },
          { value: true, label: 'Insured' },
        ]}
      />
      <Collapse in={insured} timeout={500} unmountOnExit>
        <TextField
          name='insuranceProvider'
          label='Insurance Provider'
          value={insuranceProvider}
          onChange={e => {
            setInsuranceProvider(e.target.value)
          }}
        />
      </Collapse>

      <Box mt={2}>
        <Button fullWidth onClick={processProfileUpdate} variant='contained' color='primary'>
          {savingData}
        </Button>
      </Box>
    </Box>
  )
}

export default ProfileForm
