import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCurrentProfile } from '../context/CurrentUser'
import { useVoucherDetails } from '../context/VoucherDetails'
import Button from '../stories/Button'
import Typography from '../stories/Typography'

const ViewVoucherDetailsButton = ({ voucher }) => {
  const { acceptedTerms, isProvider } = useCurrentProfile()
  const { handleClick } = useVoucherDetails()

  const onClick = useCallback(() => {
    if (acceptedTerms || !isProvider) {
      handleClick(voucher)
    }
  }, [acceptedTerms, isProvider, voucher]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Button
      size='small'
      variant='outlined'
      onClick={onClick}
      disabled={!acceptedTerms && isProvider}
    >
      <Typography variant='body1' family='poppins'>
        View Details
      </Typography>
    </Button>
  )
}

ViewVoucherDetailsButton.propTypes = {
  voucher: PropTypes.shape({
    uid: PropTypes.string,
    code: PropTypes.string,
    service: PropTypes.shape({
      name: PropTypes.string,
    }),
    customer: PropTypes.shape({
      lastname: PropTypes.string,
    }),
  }).isRequired,
}

export default ViewVoucherDetailsButton
