import { useEffect, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import PatientVouchers from '../containers/PatientVouchers'
import ProviderVouchers from './ProviderVouchers'
import ProfileForm from '../components/ProfileForm'
import OrdersDashboardContent from './OrdersDashboardContent'
import PlansDashboardContent from './PlansDashboardContent'
import PlansPatientDashboardContent from './PlansPatientDashboardContent'
import ReportsContent from './ReportsContent'
import ProviderOrders from './ProviderOrders'
import ChangePasswordForm from '../containers/ChangePasswordForm'
import VoucherDetailsContainer from '../containers/VoucherDetailsContainer'
import { useCurrentProfile } from '../context/CurrentUser'
import { useVoucherDetails } from '../context/VoucherDetails'
import useSteps from '../hooks/useSteps'
import Tabs, { Tab } from '../stories/Tabs'
import Box from '@mui/material/Box'
import Heading from '../stories/Heading'
import Link from '../stories/Link'
import AddNoteModal from '../containers/AddNoteModalContainer'
import AddOrderModal from '../containers/AddOrderModalContainer'
import parseQueryString from '../libs/parseQueryStrings'
import { useVouchers } from '../context/Vouchers'
import IdInputForm from './IdInputForm'
// import ShippingAddressContainer from '../containers/ShippingAddressContainer'
import PreferencesForm from './PreferencesForm'

const DashboardTabs = () => {
  const { search } = useLocation()
  const { step, changeStep } = useSteps()

  const { vouchers } = useVouchers()

  const { voucher, handleClick: voucherClick } = useVoucherDetails()

  const { uid, organizationId, isPatient, ...profile } = useCurrentProfile()

  const { vc } = parseQueryString(search)

  const voucherCodeQuery = useRef(vc)

  useEffect(() => {
    if (voucherCodeQuery.current && vouchers) {
      if (voucherCodeQuery.current) {
        const voucherRef = vouchers.find(v => v.code === voucherCodeQuery.current)
        if (voucherRef) {
          voucherClick(voucherRef)
          voucherCodeQuery.current = null
        }
      }
    }
  }, [voucherCodeQuery.current, vouchers]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Paper>
        <Tabs
          value={step}
          onChange={changeStep}
          scrollButtons
          variant='scrollable'
          allowScrollButtonsMobile
        >
          <Tab label='Vouchers' size='growXS' />
          <Tab label='My Info' size='growXS' />
          <Tab label='Security Info' size='growXS' />
          <Tab label='Orders' size='growXS' />
          <Tab label='Reports' size='growXS' />
          {/* <Tab label='Chats' size='growXS' />  */}
          <Tab label='Plans' size='growXS' />
        </Tabs>
      </Paper>
      {step === 0 && (
        <Box>
          {isPatient && !voucher && <PatientVouchers uid={uid} />}
          {!isPatient && !voucher && <ProviderVouchers uid={organizationId} />}
          {voucher && (
            <>
              <VoucherDetailsContainer />
              <AddNoteModal />
              <AddOrderModal />
            </>
          )}
        </Box>
      )}
      {step === 1 && (
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography variant='h3'>Personal Information</Typography>
            <ProfileForm uid={uid} values={profile} />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography variant='h3' marginBottom='32px'>
            Addresses
          </Typography>
          <ShippingAddressContainer uid={uid} values={profile} />
        </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography variant='h3' marginBottom='16px'>
              Preferences
            </Typography>
            <PreferencesForm uid={uid} values={profile} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography variant='h3' marginBottom='16px'>
              Government ID
            </Typography>
            <IdInputForm />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Heading variant='h3'>Request personal information</Heading>
            <br />
            <Typography>
              To request or delete your personal data, please contact us at{' '}
              <Link type='external' display='inline' to='mailto:help@mishe.co'>
                help@mishe.co
              </Link>{' '}
              or{' '}
              <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
                1-855-MISHECO
              </Link>{' '}
              (
              <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
                1-855-647-4326
              </Link>
              ).
            </Typography>
            <br />
            {isPatient && (
              <>
                <Typography>
                  You can also check our{' '}
                  <Link target='_blank' display='inline' size='small' to='/hipaa'>
                    HIPAA
                  </Link>{' '}
                  and{' '}
                  <Link target='_blank' display='inline' size='small' to='/eula-consumer'>
                    EULA
                  </Link>{' '}
                  agreements.
                </Typography>
                <br />
              </>
            )}
            {!isPatient && (
              <Typography>
                You can also check our{' '}
                <Link target='_blank' display='inline' size='small' to='/baa'>
                  BAA
                </Link>{' '}
                and{' '}
                <Link target='_blank' display='inline' size='small' to='/eula-provider'>
                  EULA
                </Link>{' '}
                agreements.
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      {step === 2 && (
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={8} md={5} lg={4}>
            <ChangePasswordForm />
          </Grid>
        </Grid>
      )}
      {step === 3 && (
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {isPatient && <OrdersDashboardContent />}
            {!isPatient && <ProviderOrders />}
          </Grid>
        </Grid>
      )}
      {step === 4 && (
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ReportsContent />
          </Grid>
        </Grid>
      )}
      {step === 5 && (
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {isPatient ? <PlansPatientDashboardContent /> : <PlansDashboardContent />}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default DashboardTabs
