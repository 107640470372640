import { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import { QRCodeCanvas} from 'qrcode.react'
import { Grid, Divider } from '@mui/material'
import Chip from './VoucherChip'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'
import Button from '../stories/Button'
import { Toggle } from '../hooks/useToggleOpen'
import Link from '../stories/Link'
import ViewVoucherDetailsButton from '../containers/ViewVoucherDetailsButton'
import { collection } from '../firebase/firestore'
import OrderChatView from './OrderChatView'
import Typography from '../stories/Typography'
import AddToCalender from './AddToCalender'
import { checkIfDate } from '../libs/validators'
import { formatDateForUI } from '../libs/formatters'

const PatientVouchersRow = ({ row = [] }) => {
  const [subVouchers, setSubVouchers] = useState([])

  const [addToCalenderOpen, setAddToCalenderOpen] = useState(false)

  const onClickAddToCalender = () => {
    setAddToCalenderOpen(!addToCalenderOpen)
  }

  useEffect(() => {
    const getSubVouchers = async () => {
      console.log('Grabbing bundle vouchers')
      const vouchers = await collection('vouchers')
        .doc(row.uid)
        .collection('subVouchers')
        .where('user.id', '==', row.user.id)
        .get()
        .catch(error => {
          console.log('Error getting sub vouchers')
          console.log(error)
        })

      if (vouchers.empty) return undefined

      const subVoucherData = vouchers.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
          uid: doc.id,
        }
      })
      setSubVouchers(subVoucherData)
    }

    if (row?.bundle) {
      getSubVouchers()
    }
  }, [row.bundle]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Toggle>
      {({ open, handleClick, handleClose }) => (
        <Fragment>
          <TableRow>
            <TableCell align='center'>
              <IconButton
                aria-label={open ? 'close row' : 'expand row'}
                size='small'
                onClick={open ? handleClose : handleClick}
              >
                {open ? <Icon name='closeArrow' /> : <Icon name='openArrow' />}
              </IconButton>
            </TableCell>
            <TableCell>
              {row?.organization?.name && (
                <Link
                  to={row?.organization?.slug ? `/clinics/${row?.organization?.slug}` : ''}
                  display='inline'
                  variant='unstyled'
                  size='small'
                >
                  {row.organization.name || ''}
                </Link>
              )}
            </TableCell>
            <TableCell>{row.service.name}</TableCell>
            <TableCell>
              {formatDateForUI(row?.createdAt || row?.date, true) || '---'}
            </TableCell>
            <TableCell>{row.code}</TableCell>
            <TableCell>
              {formatDateForUI(row?.appointmentDate, true) || '---'}
            </TableCell>
            <TableCell>{row?.organization?.phone || '---'}</TableCell>
            <TableCell align='center'>
              {row.bundle ? <Chip label='bundle' /> : <Chip label={row.status} />}
            </TableCell>
          </TableRow>
          <TableRow>
            {!row.bundle && <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />}
            <TableCell style={{ padding: '0px' }} colSpan={row.bundle ? 8 : 5}>
              {row.bundle && (
                <Collapse in={open} timeout='auto' unmountOnExit>
                  <Box>
                    <Box
                      width='100%'
                      display='flex'
                      padding='8px'
                      borderBottom='1px solid #ddd'
                      alignItems='center'
                    >
                      <Box>
                        <OrderChatView
                          order={row}
                          chatID={row?.id || row?.uid}
                          color='#652d92'
                          variant='bundle'
                        />
                      </Box>
                    </Box>
                    <Grid container>
                      <Grid item xs={2}>
                        <Box padding='10px' bgcolor='#f6eeff'>
                          <Typography variant='body2' weight='medium'>
                            Service
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box padding='10px' bgcolor='#f6eeff'>
                          <Typography variant='body2' weight='medium'>
                            Clinic
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box padding='10px' bgcolor='#f6eeff'>
                          <Typography variant='body2' weight='medium'>
                            Appt Date
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box padding='10px' bgcolor='#f6eeff'>
                          <Typography variant='body2' weight='medium'>
                            Voucher ID
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box padding='10px' bgcolor='#f6eeff'>
                          <Typography variant='body2' weight='medium' align='center'>
                            Status
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      {subVouchers.length > 0 &&
                        subVouchers.map(subVoucher => (
                          <>
                            <Grid item xs={2}>
                              <Box
                                bgcolor='#faf5ff'
                                padding='10px'
                                height='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='flex-start'
                              >
                                {subVoucher?.service?.name || '---'}
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                bgcolor='#faf5ff'
                                padding='10px'
                                height='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='flex-start'
                              >
                                {subVoucher?.organization?.name || '---'}
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                bgcolor='#faf5ff'
                                padding='10px'
                                height='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='flex-start'
                              >
                                {formatDateForUI(subVoucher?.appointmentDate, true) || '---'}
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                bgcolor='#faf5ff'
                                padding='10px'
                                height='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='flex-start'
                              >
                                <ViewVoucherDetailsButton voucher={subVoucher} />
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                bgcolor='#faf5ff'
                                padding='10px'
                                height='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='flex-start'
                              >
                                {subVoucher.code}
                              </Box>
                            </Grid>
                            <Grid item xs={2} align='center'>
                              <Box
                                bgcolor='#faf5ff'
                                padding='10px'
                                height='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                              >
                                <Chip
                                  label={subVoucher.status}
                                  style={{
                                    margin: '0px',
                                  }}
                                />
                              </Box>
                            </Grid>
                          </>
                        ))}
                      {/* Pete this is the idea behind adding services to bundles / converting vouchers to bundles */}
                      {/* <Grid item sm={12}>
                      <Box
                        padding='10px'
                        bgcolor='#f6eeff'
                        display='flex'
                        alignItems='center'
                        style={{
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box>
                          <Typography variant='body2' weight='medium'>
                            Add more services to this bundle
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={() => {
                              console.log('Add services to bundle')
                            }}
                          >
                            Add Services
                          </Button>
                        </Box>
                      </Box>
                    </Grid> */}
                    </Grid>
                  </Box>
                </Collapse>
              )}
              {!row.bundle && (
                <Collapse in={open} timeout='auto' unmountOnExit>
                  <Box display='flex' alignItems='center' my={2}>
                    <Icon name='price' fontSize='small' color='primary' />
                    <Box fontWeight={500} ml={2}>
                      Order:
                    </Box>
                    <Box ml={1} mr={2}>
                      {row.orderId}
                    </Box>
                    <span>|</span>
                    <Box fontWeight={500} ml={2}>
                      Price:
                    </Box>
                    <Box ml={1} mr={2}>
                      ${row.service?.price}
                    </Box>
                  </Box>
                  <Box display='flex' alignItems='center' my={2}>
                    <Icon name='calendar' fontSize='small' color='primary' />
                    <Box fontWeight={500} ml={2}>
                      Scheduling Instructions:
                    </Box>
                    <Box ml={1} mr={2}>
                      {row?.organization?.instructions || '---'}
                    </Box>
                  </Box>
                  {(row?.telehealthLink || row?.organization?.telehealthLink) && (
                    <Box display='flex' alignItems='center' my={2}>
                      <Box fontWeight={500} ml={2}>
                        Video Call Room:
                      </Box>
                      <Box ml={1} mr={2}>
                        <Link
                          to={row?.telehealthLink || row?.organization?.telehealthLink || ''}
                          type='external'
                          display='inline'
                          variant='unstyled'
                          size='small'
                          target='_blank'
                          style={{
                            padding: '5px 10px',
                            borderRadius: '5px',
                            backgroundColor: '#662D91',
                            color: '#fff',
                            boxShadow: '1px 1px 5px 0px #662D91',
                          }}
                        >
                          Join External Call
                        </Link>
                      </Box>
                    </Box>
                  )}
                  <Box display='flex' alignItems='center' my={2}>
                    <Box fontWeight={500} ml={2}>
                      Video Call Room:
                    </Box>
                    <Box ml={1} mr={2}>
                      <Link
                        to={`/videochat?id=${row.uid}`}
                        type='internal'
                        display='inline'
                        variant='unstyled'
                        size='small'
                        target='_blank'
                        style={{
                          padding: '5px 10px',
                          borderRadius: '5px',
                          backgroundColor: '#662D91',
                          color: '#fff',
                          boxShadow: '1px 1px 5px 0px #662D91',
                        }}
                      >
                        Join Mishe Video Call
                      </Link>
                    </Box>
                  </Box>
                  {row.bookingLink && (
                    <Box display='flex' alignItems='center' my={2}>
                      <Box fontWeight={500} ml={2}>
                        Booking Link:
                      </Box>
                      <Box ml={1} mr={2}>
                        <Link
                          to={row.bookingLink}
                          type='external'
                          display='inline'
                          variant='unstyled'
                          size='small'
                          target='_blank'
                          style={{
                            padding: '5px 10px',
                            borderRadius: '5px',
                            backgroundColor: '#662D91',
                            color: '#fff',
                            boxShadow: '1px 1px 5px 0px #662D91',
                          }}
                        >
                          Book here
                        </Link>
                      </Box>
                    </Box>
                  )}
                  <Box display='flex' alignItems='center' my={2}>
                    <Icon name='notes' fontSize='small' color='primary' />
                    <Box fontWeight={500} ml={2}>
                      Notes
                    </Box>
                    <Box ml={2}>
                      <ViewVoucherDetailsButton voucher={row} />
                    </Box>
                    {checkIfDate(row?.appointmentDate) && (
                      <>
                        {console.log(row.appointmentDate)}
                        <Box ml={3}>
                          <Button size='small' variant='outlined' onClick={onClickAddToCalender}>
                            <Typography variant='body1' family='poppins'>
                              Add To Calender
                            </Typography>
                          </Button>
                        </Box>
                        {/* We should make a formatter to handle the to ISO string similar to our formatDateForUI helper function */}
                        <AddToCalender
                          calenderEvent={{
                            title: row.service.name,
                            description: `Voucher Link:  ${window.location.origin}/dashboard?vc=${row.code}`,
                            start: row?.appointmentDate?.toDate()?.toISOString(),
                            appointmentDate: row.appointmentDate,
                            duration: [1, 'hour'],
                          }}
                          open={addToCalenderOpen}
                          onClose={onClickAddToCalender}
                        />
                      </>
                    )}
                  </Box>
                </Collapse>
              )}
            </TableCell>
            {!row.bundle && (
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} align='center'>
                <Collapse in={open} timeout='auto' unmountOnExit>
                  {row.status === 'purchased' && (
                    <>
                      <Box style={{ marginTop: 10 }}>
                        <QRCodeCanvas value={`${window.location.origin}/dashboard?claim=${row.code}`} />
                      </Box>
                    </>
                  )}
                  {row.status === 'cancelled' && (
                    <>
                      <Box fontWeight={500} mb={2}>
                        Cancelled at:
                      </Box>
                      <Box>{formatDateForUI(row?.cancelledAt, true)}</Box>
                    </>
                  )}

                  {row.status === 'redeemed' && (
                    <>
                      <Box>Redeemed at:</Box>
                      <Box>{formatDateForUI(row?.redeemedAt, true) || '---'}</Box>
                    </>
                  )}
                </Collapse>
              </TableCell>
            )}
          </TableRow>
        </Fragment>
      )}
    </Toggle>
  )
}

PatientVouchersRow.propTypes = {
  row: PropTypes.shape({
    uid: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    status: PropTypes.string,
    orderId: PropTypes.string,
    code: PropTypes.string,
    cancelledAt: PropTypes.instanceOf(Date),
    redeemedAt: PropTypes.instanceOf(Date),
    bundle: PropTypes.bool,
    customer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    service: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    organization: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      phone: PropTypes.string,
      instructions: PropTypes.string,
      telehealthLink: PropTypes.string,
    }),
    // string or date
    appointmentDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(firebase.firestore.Timestamp),
    ]),
    telehealthLink: PropTypes.string,
    bookingLink: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

export default PatientVouchersRow
