import PropTypes from 'prop-types'
import styled from 'styled-components'
import MuiAppBar from '@mui/material/AppBar'
import MuiToolBar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'

const StyledAppBar = styled(MuiAppBar)`
  background: ${props => props.$bgColor || '#652d92'};
  color: black;
  height: ${props => props.theme.spacing(8)};
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-direction: inherit;
  @media (max-width: 768px) {
    height: ${props => props.theme.spacing(8)};
  }
`
const StyledToolBar = styled(MuiToolBar)`
  padding: 0px;
`

const AppBar = ({ children, primaryColor, secondaryColor, ...props }) => {
  return (
    <StyledAppBar position='fixed' $bgColor={primaryColor} {...props}>
      <Box width='100%' padding='0 2rem'>
        <StyledToolBar>{children}</StyledToolBar>
      </Box>
    </StyledAppBar>
  )
}

AppBar.propTypes = {
  children: PropTypes.node,
}

export default AppBar