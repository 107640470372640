// Custom hook to be used for tooltip like behavior
import { useState, useCallback } from 'react'

import { isEmail, isPassword, isPhone, isText, isGender, isDate } from '../libs/validators'

// Error messages
export const EMPTY_ERROR_MESSAGE = ' '
const INVALID_NAME_MESSAGE = 'Name must have at least 2 characters'
const INVALID_EMAIL_MESSAGE = 'Invalid e-mail format (e.g. john@example.com)'
const INVALID_PHONE_MESSAGE = 'Invalid phone format (e.g. 555 555 5555)'
// dash needs to be at the end of the regex to avoid being interpreted as a range
export const INVALID_PASSWORD_MESSAGE =
  'Password must be at least 8 characters and contain at least one number, one uppercase letter, one lowercase letter, and one special character from !@#$%^&*_+?=-'
const INVALID_CONFIRMATION_MESSAGE = 'Passwords do not match'
const INVALID_DATE_MESSAGE = 'Invalid date format (MM-DD-YYYY)'
const INVALID_GENDER_MESSAGE = 'Invalid gender option'
const WRONG_PASSWORD_MESSAGE = 'The password is invalid or the user does not have a password'
const USER_NOT_FOUND_MESSAGE = 'There is no user record corresponding to this identifier'
const ALREADY_EXISTS_MESSAGE = 'E-mail already in use'

// Error codes: https://firebase.google.com/docs/auth/admin/errors
export const CODES = {
  TERMS_NOT_ACCEPTED: 'auth/terms-not-accepted',
  HIPAA_NOT_ACCEPTED: 'auth/hipaa-not-accepted',
  ALREADY_EXISTS: 'auth/email-already-in-use',
  INVALID_EMAIL: 'auth/invalid-email',
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password',
  INVALID_PASSWORD: 'auth/invalid-password',
  INVALID_CONFIRMATION: 'auth/invalid-confirmation',
  INVALID_PHONE: 'auth/invalid-phone-number',
  INVALID_FIRST_NAME: 'auth/invalid-first-name',
  INVALID_LAST_NAME: 'auth/invalid-last-name',
  INVALID_GENDER: 'auth/invalid-gender',
  INVALID_DATE: 'auth/invalid-date',
}

// Get list of error codes from form elements
const validate = (
  {
    firstname,
    lastname,
    gender,
    dob,
    phone,
    email,
    hipaa,
    terms,
    password,
    passwordConfirmation,
  },
  { includePwd = true, includeEmail = true } = {},
) => {
  const errorList = []
  if (!isText(firstname.value)) {
    errorList.push(CODES.INVALID_FIRST_NAME)
  }
  if (!isText(lastname.value)) {
    errorList.push(CODES.INVALID_LAST_NAME)
  }
  if (includeEmail && !isEmail(email.value)) {
    errorList.push(CODES.INVALID_EMAIL)
  }
  if (!isPhone(phone.value)) {
    errorList.push(CODES.INVALID_PHONE)
  }
  if (includePwd) {
    if (!isPassword(password.value)) {
      errorList.push(CODES.INVALID_PASSWORD)
    }
    if (password.value !== passwordConfirmation.value) {
      errorList.push(CODES.INVALID_CONFIRMATION)
    }
  }
  if (!isGender(gender.value)) {
    errorList.push(CODES.INVALID_GENDER)
  }
  if (!isDate(dob.value)) {
    console.log('dob', dob.value)
    errorList.push(CODES.INVALID_DATE)
  }
  if (terms && !terms.checked) {
    errorList.push(CODES.TERMS_NOT_ACCEPTED)
  }
  if (hipaa && !hipaa.checked) {
    errorList.push(CODES.HIPAA_NOT_ACCEPTED)
  }
  return errorList
}

// Get error message from error code
const getErrorByCode = error => {
  switch (error.code || error) {
    case CODES.ALREADY_EXISTS:
      return { email: ALREADY_EXISTS_MESSAGE }
    case CODES.USER_NOT_FOUND:
      return { email: USER_NOT_FOUND_MESSAGE }
    case CODES.WRONG_PASSWORD:
      return { password: WRONG_PASSWORD_MESSAGE }

    case CODES.INVALID_FIRST_NAME:
      return { firstname: INVALID_NAME_MESSAGE }
    case CODES.INVALID_LAST_NAME:
      return { lastname: INVALID_NAME_MESSAGE }
    case CODES.INVALID_EMAIL:
      return { email: INVALID_EMAIL_MESSAGE }
    case CODES.INVALID_PHONE:
      return { phone: INVALID_PHONE_MESSAGE }
    case CODES.INVALID_PASSWORD:
      return { password: INVALID_PASSWORD_MESSAGE }
    case CODES.INVALID_CONFIRMATION:
      return { passwordConfirmation: INVALID_CONFIRMATION_MESSAGE }
    case CODES.INVALID_DATE:
      return { dob: INVALID_DATE_MESSAGE }
    case CODES.INVALID_GENDER:
      return { gender: INVALID_GENDER_MESSAGE }
    case CODES.TERMS_NOT_ACCEPTED:
      return { terms: EMPTY_ERROR_MESSAGE }
    case CODES.HIPAA_NOT_ACCEPTED:
      return { hipaa: EMPTY_ERROR_MESSAGE }

    default:
      return {
        email: EMPTY_ERROR_MESSAGE,
        password: EMPTY_ERROR_MESSAGE,
      }
  }
}

// User form error hook
function useAuthErrors(options) {
  const [errors, setErrors] = useState({})

  const setMultipleErrors = useCallback(errorList => {
    const result = errorList.reduce((accu, each) => ({ ...accu, ...getErrorByCode(each) }), {})
    setErrors(result)
  }, [])

  const setSingleError = useCallback(error => {
    const result = getErrorByCode(error)
    setErrors(result)
  }, [])

  const handleSubmit = useCallback(
    callback => async (event, addressState) => {
      event.preventDefault()
      const { elements } = event.target
      const errorMessages = validate(elements, options)
      console.log('errorMessages', errorMessages)
      setMultipleErrors(errorMessages)
      if (errorMessages.length === 0) {
        try {
          await callback(elements, addressState)
        } catch (error) {
          console.log('Hanlde submit error')
          console.warn('error', error)
          // firebase returned an error during signup
          setSingleError(error)
        }
      }
    },
    [options, setMultipleErrors, setSingleError],
  )

  return [errors, handleSubmit, setSingleError]
}

export default useAuthErrors
