import React from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { DatePicker } from '@mui/x-date-pickers'
import { formatDateForDatePicker, birthdayFormatter } from '../libs/formatters'

const DobPicker = ({ dob, setDob }) => {
  return (
    <DatePicker
      fullWidth
      inputVariant='outlined'
      format='MM-dd-yyyy'
      margin='normal'
      id='date-picker-inline'
      label='Birthday'
      name='birthday'
      timezone='UTC'
      maxDate={new Date()}
      value={formatDateForDatePicker(dob) ? formatDateForDatePicker(dob) : null}
      onChange={date => {
        setDob(birthdayFormatter(date))
      }}
      InputLabelProps={{ shrink: true }}
      keyboardIcon={<DateRangeIcon />}
      KeyboardButtonProps={{ 'aria-label': 'change date' }}
      slotProps={{ textField: { fullWidth: true } }}
    />
  )
}

export default DobPicker
